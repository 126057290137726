import { registerApplication, start, navigateToUrl } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./layout.html";

import { AuthManager } from "./auth0";

// Construct routes and applications
const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

// Register and start Single SPA app
const registerSpaApp = (user, auth0) => {
  applications.forEach((props) => {
    // Add custom properties accessible to sub applications
    props.customProps = {
      examplePropRootConfig: "Example data from root Single SPA",
      authUser: user,
      auth0: auth0,
    };
    registerApplication(props);
  });
  navigateToUrl(sessionStorage.getItem("postLoginRedirect"));
};

const authManager = new AuthManager();
authManager.initialize(registerSpaApp);
layoutEngine.activate();

start({
  urlRerouteOnly: true,
});

// Global event listeners from Micro front ends
window.addEventListener("signout", () => {
  console.log("Clearing localStorage...");
  localStorage.clear();
  console.log("Logging out...");
  authManager.logout();
});
