import { createAuth0Client } from "@auth0/auth0-spa-js";

export class AuthManager {
  auth0Client;
  user;

  async initAuth0() {
    this.auth0Client = await createAuth0Client({
      domain: process.env.AUTH0_DOMAIN,
      clientId: process.env.AUTH0_CLIENT_ID,
      authorizationParams: {
        audience: process.env.AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      },
    });
  }

  async isAuthenticated() {
    const authenticated = await this.auth0Client.isAuthenticated();
    return authenticated;
  }

  async handleRedirectCallback() {
    await this.auth0Client.handleRedirectCallback();
  }

  async loginWithRedirect() {
    await this.auth0Client.loginWithRedirect({
      redirect_uri: window.location.origin,
    });
  }

  async logout() {
    await this.auth0Client.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  async initialize(registerSpaApps) {
    // Initialize Auth0
    await this.initAuth0();

    // Check if the user is authenticated
    const isLoggedIn = await this.isAuthenticated();

    if (isLoggedIn) {
      // Store the redirection URL for post-login
      sessionStorage.setItem("postLoginRedirect", window.location.href);
      this.user = await this.auth0Client.getUser();
    } else {
      // Check for query parameters indicating a redirect callback
      const hasQueryParams =
        window.location.search.includes("code=") &&
        window.location.search.includes("state=");

      if (hasQueryParams) {
        try {
          await this.handleRedirectCallback();
        } catch (error) {
          console.error(error);
        }

        // Check authentication status after redirect callback
        const isLoggedIn = await this.isAuthenticated();

        if (!isLoggedIn) {
          // Store the redirection URL and initiate login
          sessionStorage.setItem("postLoginRedirect", window.location.href);
          await this.loginWithRedirect();
        } else {
          this.user = await this.auth0Client.getUser();
        }
      } else {
        // Store the redirection URL and initiate login
        sessionStorage.setItem("postLoginRedirect", window.location.href);
        await this.loginWithRedirect();
      }
    }

    // Register Single SPA apps with user and Auth0 client
    registerSpaApps(this.user, this.auth0Client);
  }
}
